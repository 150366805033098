import { SearchParamsModel } from '@/components/utils/constants';
import {
  IAppContext,
  IAvailability,
  IAvailabilityRateCode,
  ICouponRateCodeStatamic,
  IFilters,
  IOrder, IOrderItem,
  IPackageCode, IPackages,
  IPrice,
  ISearchForm,
  Reservation
} from '@/interfaces/bookingContext';
import i18n from 'i18next';
import {
  IAccommodationBoardsInfo,
  IAccommodationMoreInfo,
  IProperty,
  IRateTerms,
  IStays,
  IWebsite
} from '@/interfaces/huWebsite';
import { IProfile } from '@/interfaces/suite8';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear.js';
import websites from '../settings/websites';
import DOMPurify from 'dompurify';
import { isObject } from '@mui/x-data-grid/internals';

dayjs.extend(dayOfYear);

const rateCodesPayable = ['AS', 'AX', 'NR'];

export const getCurrentWebsite = (): IWebsite => {
  const website = websites.find((website) => website.hostnames.includes(window.location.hostname));
  if (!website) {
    throw new TypeError(`Domain ${window.location.hostname} is not configured`);
  }
  return website;
};

export const website = getCurrentWebsite();

export const getDataFromUrl = () => {


  const x = sessionStorage.getItem('searchForms');

  if (x) {
    const forms: ISearchForm[] = JSON.parse(x);
    // forms[0].status = 'to_start';
    return forms;
  }

  const query: URLSearchParams = new URLSearchParams(window.location.search);

  const searchParamsModel = SearchParamsModel;

  if (query.get('property')) {
    searchParamsModel.property = String(query.get('property'));
  }

  if (
    dayjs(query.get('GuestArrival'), 'DD.MM.YYYY', true).isValid() &&
    dayjs(query.get('GuestDeparture'), 'DD.MM.YYYY', true).isValid()
  ) {
    searchParamsModel.GuestArrival = dayjs(query.get('GuestArrival'), 'DD.MM.YYYY').format('YYYY-MM-DD');
    searchParamsModel.GuestDeparture = dayjs(query.get('GuestDeparture'), 'DD.MM.YYYY').format('YYYY-MM-DD');
  }
  if (query.get('NoOfAdults')) {
    searchParamsModel.NoOfAdults = Number(query.get('NoOfAdults'));
  }
  if (query.get('coupon')) {
    searchParamsModel.Coupon = String(query.get('coupon'));
    searchParamsModel.Coupon = searchParamsModel.Coupon.toString().replace(/^\uFEFF/, '').trim();
  }
  /*if (query.get('pet')) {
        searchParamsModel.Pets = true
    }*/


  return [{ ...searchParamsModel }];
};

export const getLabelOffer = (availabilityRateCode: any) => {
  const bigPrice =
    availabilityRateCode.Price > availabilityRateCode.PriceStroked
      ? availabilityRateCode.Price
      : availabilityRateCode.PriceStroked;
  const lowPrice =
    availabilityRateCode.Price < availabilityRateCode.PriceStroked
      ? availabilityRateCode.Price
      : availabilityRateCode.PriceStroked;
  return -Math.round(Math.abs(((lowPrice - bigPrice) / bigPrice) * 100)) + '%';
};

export const getStringFromRateDesc = (availabilityRateCode: IAvailabilityRateCode): string => {
  if (
    availabilityRateCode.RateCodeCouponStatamic &&
    availabilityRateCode.RateCodeCouponStatamic.lista_ratecode
  ) {
    const StatamicRate = availabilityRateCode.RateCodeCouponStatamic.lista_ratecode.find(
      (r: ICouponRateCodeStatamic) => r.RateCode === availabilityRateCode.RateCode
    );
    if (StatamicRate && StatamicRate.RateCodeDescription !== '') {
      return ' | ' + StatamicRate.RateCodeDescription;
    }
  }

  let str = '';
  if (availabilityRateCode.RateCodeDetails.RateName.indexOf('MIN_') > -1) {
    str = i18n.t('rate_MS', {
      params: {
        NIGHTS: availabilityRateCode.RateCodeDetails.RateMinLOS
      }
    });

    str = ' - ' + str;
    return str;
  }

  const showRateNameIn = ['W0', 'W1', 'W2', 'W7'];

  if (showRateNameIn.includes(availabilityRateCode.RateCode.substring(0, 2))) {
    str = ' - ';

    str += availabilityRateCode.RateCodeCouponStatamic
      ? availabilityRateCode.RateCodeCouponStatamic.title
      : availabilityRateCode.RateCodeDetails.RateName;

    return str;
  }

  return str;
};

export const getStringFromRateDescByOrder = (
  availability: IAvailability[],
  RoomType: String,
  RateCode: String
) => {
  const room = availability.find((av) => av.RoomTypeShortDesc === RoomType);
  if (room) {
    const rate = room.RateCodes.find((rc) => rc.RateCode === RateCode);
    if (rate) {
      return getStringFromRateDesc(rate);
    } else {
      return '';
    }
  } else {
    return '';
  }
};

/**
 * Get deposit days, please use from properties.json
 * @deprecated
 */
export const getDepositDays = () => {
  return 30;
};

export const getPropertiesByArea = (destination: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return properties.filter((property) =>
    property.searchFormFilters.destinations.includes(destination)
  );
  // return properties.filter((property) => property.tags.includes(website));
};

export const getBoardInfoBy = (
  boardInfo: IAccommodationBoardsInfo[],
  BoardRateCode: string,
  RoomType: string
): IAccommodationBoardsInfo => {
  let board: IAccommodationBoardsInfo | undefined = {
    terms_description: '',
    title: ''
  };
  const RoomCategoryCode = RoomType.slice(0, 2);

  board = boardInfo.find((bI) => bI.code === BoardRateCode + '_' + RoomCategoryCode);
  if (board && board.terms_description) {
    board = {
      ...board,
      terms_description: board.terms_description.replace(/(<([^>]+)>)/gi, '')
    };
    return board;
  }
  board = boardInfo.find((bI) => bI.code === BoardRateCode);
  if (board && board.terms_description) {
    board = {
      ...board,
      terms_description: board.terms_description.replace(/(<([^>]+)>)/gi, '')
    };
    return board;
  }

  return board as IAccommodationBoardsInfo;
};
export const getCouponInfoBy = (availabilityRateCode: IAvailabilityRateCode) => {
  if (
    availabilityRateCode.RateCodeCouponStatamic &&
    availabilityRateCode.RateCodeCouponStatamic.lista_ratecode
  ) {
    const StatamicRate = availabilityRateCode.RateCodeCouponStatamic.lista_ratecode.find(
      (r: ICouponRateCodeStatamic) => r.RateCode === availabilityRateCode.RateCode
    );
    if (StatamicRate && StatamicRate.RateCodeDescription !== '') {
      return ' | ' + StatamicRate.RateCodeDescription;
    }
  }

  return !availabilityRateCode.RateCodeCouponStatamic
    ? ''
    : '| ' + availabilityRateCode.RateCodeCouponStatamic.title;
};

export const getRateCodeDetail = (rateTerms: IRateTerms[], term: string, key = 'title'): string => {
  const x: any = rateTerms.find((t: IRateTerms) => t.code === term);
  if (x !== undefined && x[key]) {
    return x[key].replace(/(<([^>]+)>)/gi, '');
  } else {
    return '';
  }
};


const getGtmPurchaseObjectHC = (order: IOrder) => {
  const gtmPurchase = {
    currencyCode: 'EUR',
    actionField: {
      id: order.GuestNum,
      affiliation: window.location.hostname,
      revenue: order.TotalStay,
      tax: 0,
      shipping: 0,
      coupon: order.RateCode,
      option: 'stripe'
    },
    products: [
      {
        name: order.RoomType,
        id: order.Property + '_' + order.GuestNum,
        price: order.TotalStay,
        brand: 'HU',
        category: order.Property,
        variant: '',
        quantity: 1,
        dimension4: 'VI', // Tipo Struttura
        dimension5: '6', // RoomTypeMaxPAX
        dimension6: order.NoOfAdults + order.ageOfChildren.length, // Total guest
        dimension7: order.NoOfAdults, // Adults
        dimension8: order.ageOfChildren.length, // Children
        dimension9: dayjs(order.GuestArrival, 'DD.MM.YYYY').format('Y'),
        dimension10: dayjs(order.GuestArrival, 'DD.MM.YYYY')
          .dayOfYear()
          .toString()
          .padStart(3, '0'),
        dimension11: order.RateCode.split('_')[1],
        dimension12: '',
        dimension13: 'IT'
      }
    ]
  };
  return gtmPurchase;
};


export const getTradeTrackerCookie = () => {
  const prefix = 'TT2_';
  const splitTT2 = document.cookie.split(prefix);
  if (splitTT2.length > 1) {
    return prefix + splitTT2[1].split('=')[0];
  } else {
    return null;
  }
};

export const getPayerFromOrders = (orders: IOrder[]): IProfile | null => {
  const firstBookerID = orders[0].BookerID;

  /*for (const order of orders) {
    if (order.profiles) {
      const profile = order.profiles.find(
        (profile: IProfile) => profile.ProfileID === firstBookerID
      );
      if (profile) {
        return profile;
      }
    }
  }*/
  return null;
};

export const setIsVisible = (availability: IAvailability[]): IAvailability[] => {
  availability
    .map((av: IAvailability) => {
      av.RateCodes.map((rateCode: IAvailabilityRateCode) => (rateCode.isVisible = true));
      if (
        av.RateCodes.find(
          (rateCode: IAvailabilityRateCode) => rateCode.RateCodeCouponStatamic !== null
        )
      ) {
        av.RateCodes.filter(
          (rateCode: IAvailabilityRateCode) => rateCode.RateCodeCouponStatamic === null
        ).map((rateCode: IAvailabilityRateCode) => (rateCode.isVisible = false));
      }
      return { ...av };
    })
    .map((av: IAvailability) => {
      // av.Price = av.RateCodes.filter((rt: IAvailabilityRateCode) => rt.isVisible).reduce((prev: IAvailabilityRateCode, curr: IAvailabilityRateCode) => prev.Price < curr.Price ? prev : curr).Price;
      return { ...av };
    });

  return availability;
};

export const isPaymentRequired = (rateCode: string): boolean => {
  const rateRequirePayment: string[] = ['AS', 'AX', 'NR'];
  return rateRequirePayment.includes(rateCode.slice(-2));
};

export const devToolsShowIf = () => {
  return (
    window.location.host.indexOf('localhost') > -1 ||
    window.location.host.indexOf('azurestaticapps.net') > -1 ||
    localStorage.getItem('role') === 'admin'
  );
};

export const isRecaptchaVisible = () => {
  return !isPingdom();
};

const isPingdom = () => {
  return window.navigator.userAgent.toLowerCase().indexOf('pingdom') !== -1;
};

export const howPaid = (reservation: Reservation): number => {
  let Paid = 0;
  reservation.Postings.filter((posting: any) => posting.DepartmentType === '3').map(
    (posting: any) => (Paid += parseFloat(posting.PostingPrice))
  );
  // check payment with points
  reservation.Postings.filter(
    (posting: any) => posting.DepartmentType === '1' && posting.DepartmentCode === '136'
  ).map((posting: any) => (Paid += Math.abs(parseFloat(posting.PostingPrice))));
  return Paid;
};

export const howToPay = (reservation: Reservation): number => {
  return parseFloat(reservation.TotalStay) - howPaid(reservation);
};

export const currencyFormat = (amount: number | string | undefined | null): string => {
  if (!amount) return '';
  amount = Number(amount);
  amount = Math.floor(amount * 100) / 100;
  return amount.toFixed(2).replace('.', ',');
};

export const defaultDateFormat = 'DD.MM.YYYY';

export const getTotalPrice = (appContext: IAppContext, multiplier = 0): number => {
  let sum = 0;
  const { orders } = appContext;

  orders.forEach((order: IOrder) => {
    sum += order.TotalStay ?? order.Price;
    if (order.PackageCode) {
      order.PackageCode.forEach((pack: IPackageCode) => {
        sum += pack.CalculatedPrice || 0;
      });
    }
  });

  if (multiplier) {
    sum *= multiplier;
  }
  sum = Number(sum);
  sum = Math.floor(sum * 100) / 100;

  return sum;
};

export const getDepositPrice = (appContext: IAppContext): number => {
  let sum = 0;

  const { orders } = appContext;

  orders.forEach((order: IOrder) => {
    sum += order.TotalStay ?? order.Price;
    order.PackageCode.forEach((pack: IPackageCode) => {
      sum += pack.CalculatedPrice || 0;
    });

    if (order.RateCode.indexOf('_AX') > 0) {
      sum = 100;
    } else {
      sum *= 0.3;
    }
  });

  return Number.parseFloat(sum.toFixed(2));
};

export const getPropertiesBySearchForm = (
  properties: IProperty[],
  searchForm: ISearchForm
): IProperty[] => {
  return properties
    .filter(
      (property) =>
        searchForm?.destination === 'all' ||
        searchForm?.destination === '' ||
        property.searchFormFilters.destinations.includes(searchForm.destination)
    )
    .filter(
      (property) =>
        !searchForm.typeOfHoliday ||
        property.searchFormFilters.typeOfHoliday.includes(searchForm.typeOfHoliday)
    )
    .filter(
      (property) =>
        !searchForm.landscape ||
        property.searchFormFilters.landscapes.includes(searchForm.landscape)
    )
    .filter((property) => !searchForm.property || searchForm?.property === 'all' || property.code === searchForm.property);
};

export const getPropertiesFromAvailabilities = (availabilities: IAvailability[]): string[] => {
  return availabilities.reduce((accumulator: string[], currentValue) => {
    !accumulator.includes(currentValue.Property) && accumulator.push(currentValue.Property);
    return accumulator;
  }, []);
};

export const getMinPriceFromPropertyAvailabilities = (
  availabilities: IAvailability[],
  property: string
) => {
  availabilities.reduce((res, obj) => {
    return obj.Property === property && obj.Price < res.Price ? obj : res;
  });
};

/**
 * Assign availability data, order by loading time
 * @param properties
 * @param prices
 */
export const addPricePropsToProperty = (properties: IProperty[], prices: IPrice[]) => {

  properties.map(property => {
    property.prices = [];
    return property;
  });

  if (!prices[0]?.Property) return properties;

  for (const price of prices) {

    let property: IProperty | undefined;

    if (price.Property === 'EV') {
      property = properties.find(property => property.property === price.Property && property.code === price.RoomType.slice(0, 2));
    } else {
      property = properties.find(property => property.property === price.Property);
    }

    if (!property) {
      // console.log('missed on roomfeed', price.Property, price.RoomType)
      continue;
    }

    /*if (!property.roomfeed.some((roomFeed) => price.RoomType.endsWith(roomFeed.room_id))) {
      console.log(`${price.RoomType} of ${property.code} not present on cms`)
      continue;
    }*/

    property.prices.push(price);

    property.prices = property.prices.filter((price) =>
      property && property.roomfeed.some((roomFeed) => price.RoomType.endsWith(roomFeed.room_id))
    );
  }

  return properties;

};
export const checker = (arr: string[], target: string[]) => target.every((v) => arr.includes(v));

export const getAccommodationInfo = () => {
};

export const hunify = (string: string) => {
  return string ? {
    __html: DOMPurify.sanitize(string.replace(/(\b( hu|hu )\b)/g, '<em> hu </em>'))
  } : {
    __html: ''
  };
};

export const isFieldHasError = (profilesErrors: any, profileId: string, fieldName: string) => {
  return profilesErrors && profilesErrors[profileId] && profilesErrors[profileId][fieldName] || undefined;
};

export const setFavorite = (code: string, add = true) => {

  const storageKey = 'favorite';

  let favorites = JSON.parse(window.localStorage.getItem(storageKey) ?? '[]');

  favorites = new Set(favorites);

  add ? favorites.add(code) : favorites.delete(code);

  window.localStorage.setItem(storageKey, JSON.stringify(Array.from(favorites)));

};

export const checkIsFavorite = (code: string) => {

  const storageKey = 'favorite';

  const favorites: string[] = JSON.parse(window.localStorage.getItem(storageKey) ?? '[]');

  return favorites.includes(code);

};

const isRoomAcceptPets = (rooms: IAccommodationMoreInfo[], roomCode: string) => {
  return !!rooms.find(room => room.room_id.endsWith(roomCode) && room.filters && room.filters.includes('pet-friendly'));
};

export const filterAvailability = (filters: IFilters, properties: IProperty[], searchForms: ISearchForm) => {

  const propertiesFiltered: IProperty[] = [];

  let propertiesClone = getPropertiesBySearchForm(properties, searchForms);

  for (const property of propertiesClone) {

    const propertyClone = { ...property };

    if (filters.roomCategories.length) {
      propertyClone.prices = propertyClone.prices.filter(price => filters.roomCategories.includes(price.RoomType.substring(0, 2)));
    }

    if (filters.budget.length) {
      propertyClone.prices = propertyClone.prices.filter((price => price.Price >= filters.budget[0] && price.Price <= filters.budget[1]));
    }

    if (filters.isPetsAllowed) {
      propertyClone.prices = propertyClone.prices.filter((price => propertyClone.pets_allowed && isRoomAcceptPets(propertyClone.roomfeed, price.RoomType)));
    }

    if (filters.destinationFilters) {
      // TODO: filter by destination
    }

    if (filters.servicesVisible.length) {
      const roomFiltered = propertyClone.roomfeed.filter((room: IAccommodationMoreInfo) => room.filters && filters.servicesVisible.every((s: string) => {
        return room.filters.includes(s);
      }));
      propertyClone.roomfeed = [...roomFiltered];
    }

    const x = propertyClone.prices.filter(price => propertyClone.roomfeed.filter(room => price.RoomType.endsWith(room.room_id)).length > 0);

    propertyClone.prices = [...x];

    if (propertyClone.prices.length) {
      propertiesFiltered.push(propertyClone);
    }

    for (const price of propertyClone.prices) {

      if (!propertyClone.roomfeed.some((roomFeed) => price.RoomType.endsWith(roomFeed.room_id))) {
        console.log(price.RoomType, 'not present on statamic');
        continue;
      }

      propertyClone.prices = propertyClone.prices.filter((price) =>
        propertyClone.roomfeed.some((roomFeed) => price.RoomType.endsWith(roomFeed.room_id))
      );

      const priceMin = propertyClone.prices.reduce((previousValue, currentValue) => {
        return previousValue.Price > currentValue.Price ? currentValue : previousValue;
      }, propertyClone.prices[0]);

      propertyClone.price_from = priceMin.Price;

      propertyClone.price_from_old = priceMin.PriceStroked;

      propertyClone.price_discount = Math.floor(
        100 - (propertyClone.price_from / propertyClone.price_from_old * 100)
      );
    }

  }

  return [...propertiesFiltered];

};


export const getPropertiesOfCart = (items: IOrder[], properties: IProperty[]) => {
  return items.reduce((previousValue: string[], currentValue) => {
    if (currentValue.Property === 'EV') {
      !previousValue.includes(currentValue.RoomType.slice(0, 2)) && previousValue.push(currentValue.RoomType.slice(0, 2));
    } else {
      !previousValue.includes(currentValue?.Property) && previousValue.push(currentValue?.Property);
    }
    return previousValue;
  }, [])
    .map(
      (propertyCode) =>
        properties.find((property: IProperty) => property?.code === propertyCode) ||
        ({} as IProperty)
    );
};

export const getDateRangesOfProperty = (items: IOrder[]) => {
  return items.reduce((previousValue: any[], currentValue) => {

    const x = previousValue.find(
      (item) => {
        if (currentValue.Property === 'EV') {
          return item.Property === currentValue.RateCode.slice(0, 2) &&
            item.GuestDeparture === currentValue.GuestDeparture &&
            item.GuestArrival === currentValue.GuestArrival;
        } else {
          return item.Property === currentValue.Property &&
            item.GuestDeparture === currentValue.GuestDeparture &&
            item.GuestArrival === currentValue.GuestArrival;
        }
      }
    );

    if (x) {
      x.orders.push(currentValue.item_id);
    } else {
      previousValue.push({
        Property: currentValue.Property === 'EV' ? currentValue.RoomType.slice(0, 2) : currentValue.Property,
        GuestArrival: currentValue.GuestArrival,
        GuestDeparture: currentValue.GuestDeparture,
        orders: [currentValue.item_id]
      });
    }

    return previousValue;
  }, []);
};

export const getXXX = (propertiesOfCart: IProperty[], dateRangesOfProperty: any) => {
  return propertiesOfCart.map((property: IPropertyWithOrders) => {
    property.stays = dateRangesOfProperty.filter((range: any) => {
      return property.property !== 'EV' ? range.Property === property.property : range.Property === property.code;
    });
    return property;
  });
};

export interface IPropertyWithOrders extends IProperty {
  stays: IStays[];
}

export const amountPayableWithPoints = (i: IOrder, activePoints: number): number => {

  let maxPoints = i.TotalStay;

  if (i.RateCode.endsWith('_AS') && i.pay_deposit) {
    maxPoints *= .3;
  }

  if (i.RateCode.endsWith('_AX') && i.pay_deposit) {
    maxPoints = 100;
  }

  maxPoints *= 4;

  return activePoints < maxPoints ? activePoints : maxPoints;
};

export const g: Gtag.Gtag = async (command, ...args) => {

  if (window.dataLayer && isObject(args[1])) {
    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: args[0],
      ...args[1]
    });
    console.log('window.dataLayer sent', command, {
      event: args[0],
      ...args[1]
    });
  } else {
    console.log('window.dataLayer not loaded', command, args);
  }

  /*if (window.gtag) {
    window.dataLayer
    window.gtag(command, ...args);
    console.log('gtag sent', command, ...args);
  } else {
    console.log('gtag.js not loaded', command, args);
  }*/
};

export const gEventSearchArg = (searchForm: ISearchForm, userId: string) => {

  let destinationSearch = '';
  if (searchForm.property.length) {
    destinationSearch = searchForm.property;
  } else if (searchForm.destination.length) {
    destinationSearch = searchForm.destination;
  } else if (searchForm.typeOfHoliday.length) {
    destinationSearch = searchForm.typeOfHoliday;
  } else if (searchForm.landscape.length) {
    destinationSearch = searchForm.landscape;
  }

  return {
    destination: destinationSearch,
    days: dayjs(searchForm.GuestDeparture).diff(searchForm.GuestArrival, 'days'),
    promo_code: searchForm.Coupon,
    adult_guests: searchForm.NoOfAdults,
    children_guests: searchForm.ageOfChildren.length,
    animal_guests: searchForm.Pets,
    user_data: gAddUserData(userId)
  };
};

export const gEventViewItemArg = (accommodationMoreInfo: IAccommodationMoreInfo, property: IProperty, appContext: IAppContext) => {

  const accommodationMoreInfos = [accommodationMoreInfo];

  const items = gItems(accommodationMoreInfos, property, appContext.searchForms[appContext.orderIndex]);

  return {
    ecommerce: {
      currency: 'EUR',
      value: items[0].price,
      items: items
    },
    user_data: gAddUserData(appContext.userId)
  };
};

export const gEventAddExtraArg = (pack: IPackages | IPackageCode, item: IOrder, appContext: IAppContext) => {

  const roomCategory = item.RoomType.slice(0, 2);

  const property = appContext.properties.find(p => p.code === item.Property);

  if (!property) return;

  return {
    ecommerce: {
      currency: 'EUR',
      value: 'CalculatedPrices' in pack ? pack.CalculatedPrices : pack.Price,
      items: [{
        item_id: `${property.code}-${pack.PackageCode}`,
        item_name: `${property.code}-${pack.PackageCode}`,
        affiliation: 'HuOpenAir',
        coupon: appContext.searchForms[0].Coupon,
        discount: 0,
        item_brand: item.Property,
        item_category: property.type, // VI CIT HO
        item_category2: roomCategory,
        item_category3: item.RoomType,
        item_category4: `${property.code}-${pack.PackageCode}`,
        item_list_id: roomCategory,
        item_list_name: categoriesLabelMapping[roomCategory] ?? '',
        location_id: property.location_id,
        price: 'CalculatedPrices' in pack ? pack.CalculatedPrices : pack.Price,
        quantity: 1
      }]
    },
    user_data: gAddUserData(appContext.userId)
  };
};

export const gEventViewCart = (appContext: IAppContext, property: IProperty) => {
  return {
    ecommerce: {
      currency: 'EUR',
      item_list_id: 'view_cart',
      item_list_name: 'View Cart',
      items: appContext.cart.map((c, index) => {

        const roomCategory = c.RoomType.slice(0, 2);

        return {
          item_id: `${c.Property}-${c.RoomType}`,
          item_name: `${c.Property}-${c.RoomType}`,
          affiliation: 'HuOpenAir',
          coupon: appContext.searchForms[0].Coupon,
          discount: c.discount,
          index: index,
          item_brand: c.Property,
          item_category: property.type, // VI CIT HO
          item_category2: roomCategory,
          item_category3: c.RoomType,
          item_category4: `${c.Property}-${c.RoomType}-${c.RateCode}-${c.GuestArrival}-${c.GuestDeparture}`,
          item_list_id: roomCategory,
          item_list_name: categoriesLabelMapping[roomCategory] ?? '',
          location_id: property.location_id,
          price: c.TotalStay ?? c.Price,
          quantity: 1
        };
      })
    },
    user_data: gAddUserData(appContext.userId)
  };
};

export const gEventViewItemListArg = (accommodationMoreInfos: IAccommodationMoreInfo[], appContext: IAppContext) => {

  const property = appContext.properties.find(p => p.code === appContext.propertySelected);

  if (!property) return;

  return {
    ecommerce: {
      currency: 'EUR',
      item_list_id: 'view_item_list_availability',
      item_list_name: 'View Availability - Rooms',
      items: gItems(accommodationMoreInfos, property, appContext.searchForms[appContext.orderIndex], 'view_item_list_availability', 'View Availability - Rooms')
    },
    user_data: gAddUserData(appContext.userId)
  };
};

const categoriesLabelMapping: { [key: string]: string } = {
  CM: 'hu Stay',
  BG: 'hu Stay',
  PZ: 'hu Camp',
  CA: 'hu Room',
  AP: 'hu Room',
  TN: 'hu Glamp'
};

export const gEventAddToCart = (order: IOrderItem, appContext: IAppContext) => {

  const property = appContext.properties.find(p => p.code === appContext.propertySelected);

  if (!property) return;

  const roomCategory = order.RoomType.slice(0, 2);

  const room = property.roomfeed.find(r => order.Property === 'EV' ? r.room_id.includes(order.RoomType.slice(2)) : r.room_id === order.RoomType);

  if (!room) return;

  if (!order.Price) return;

  return {
    ecommerce: {
      currency: 'EUR',
      value: order.Price,
      items: [{
        item_id: `${order.Property}-${order.RoomType}`,
        item_name: `${order.Property}-${order.RoomType}`,
        affiliation: 'HuOpenAir',
        coupon: appContext.searchForms[0].Coupon,
        discount: order.discount,
        item_brand: property.code,
        item_category: property.type, // VI CIT HO
        item_category2: roomCategory,
        item_category3: order.RoomType,
        item_list_id: roomCategory,
        item_list_name: categoriesLabelMapping[roomCategory] ?? '',
        location_id: property.location_id,
        price: order.Price,
        quantity: 1
      }]
    },
    user_data: gAddUserData(appContext.userId)
  };
};

export const gEventRemoveFromCartArg = (appContext: IAppContext) => {

  const order = appContext.cart.find(c => c.item_id === appContext.itemToRemove?.item_id);
  const property = appContext.properties.find(p => p.code === order?.Property);

  if (!order) return;
  if (!property) return;

  const roomCategory = order.RoomType.slice(0, 2);

  const room = property.roomfeed.find(r => order.Property === 'EV' ? r.room_id.includes(order.RoomType.slice(2)) : r.room_id === order.RoomType);


  if (!room) return;

  if (!order.Price) return;

  return {
    ecommerce: {
      currency: 'EUR',
      value: order.Price,
      items: [{
        item_id: `${order.Property}-${order.RoomType}`,
        item_name: `${order.Property}-${order.RoomType}`,
        affiliation: 'HuOpenAir',
        coupon: appContext.searchForms[0].Coupon,
        discount: order.discount,
        item_brand: property.code,
        item_category: property.type, // VI CIT HO
        item_category2: roomCategory,
        item_category3: order.RoomType,
        item_list_id: roomCategory,
        item_list_name: categoriesLabelMapping[roomCategory] ?? '',
        location_id: property.location_id,
        price: order.Price,
        quantity: 1
      }]
    },
    user_data: gAddUserData(appContext.userId)
  };
};

export const gEventBeginCheckoutArg = (appContext: IAppContext) => {

  const items = gItemsFromCart(appContext);

  const totalStays = getCartTotalPrice(appContext);

  return {
    ecommerce: {
      currency: 'EUR',
      value: totalStays,
      items: items
    },
    user_data: gAddUserData(appContext.userId)
  };
};

export const gEventAddCustomerInfoArg = (appContext: IAppContext) => {

  const items = gItemsFromCart(appContext);

  const totalStays = getCartTotalPrice(appContext);

  return {
    ecommerce: {
      currency: 'EUR',
      value: totalStays,
      items: items
    },
    user_data: gAddUserData(appContext.userId)
  };
};

export const gEventAddPaymentInfoArg = (appContext: IAppContext) => {

  const items = gItemsFromCart(appContext);

  const totalStays = getCartTotalPrice(appContext);

  // console.log(appContext.prof)

  return {
    ecommerce: {
      currency: 'EUR',
      value: totalStays,
      items: items
    },
    user_data: gAddUserData(appContext.userId)
  };
};

export const gEventPurchase = (appContext: IAppContext, orders: IOrder[], reservation_id: string) => {

  let paymentMethod = '';

  const items = orders.map(o => {

    const roomCategory = o.RoomType.substring(0, 2);

    const property = appContext.properties.find(p => p.code === o.Property);

    if (!property) return;

    if (o?.paymentMethod) {
      paymentMethod = o.paymentMethod;
    }

    return {
      item_id: `${o.Property}-${o.RoomType}`,
      item_name: `${o.Property}-${o.RoomType}`,
      affiliation: 'HuOpenAir',
      coupon: appContext.searchForms[0].Coupon,
      item_brand: o.Property,
      item_category: property.type, // VI CIT HO
      item_category2: roomCategory,
      item_category3: o.RoomType,
      item_category4: `${o.Property}_${o.RoomType}_${o.RateCode}_${o.GuestArrival}_${o.GuestDeparture}`,
      item_list_id: roomCategory,
      item_list_name: categoriesLabelMapping[roomCategory] ?? '',
      location_id: property.location_id,
      discount: o.discount,
      price: o.TotalStay ?? o.Price,
      quantity: 1
    };
  });

  return {
    ecommerce: {
      currency: 'EUR',
      value: items.reduce((p, c) => p + (c?.price ?? 0), 0),
      transaction_id: reservation_id,
      coupon: appContext.searchForms[0].Coupon ?? '',
      items: items,
      payment_type: paymentMethod
    },
    user_data: gAddUserData(appContext.userId)
  };
};

export const gItems = (accommodationMoreInfos: IAccommodationMoreInfo[], property: IProperty, searchForm: ISearchForm, item_list_id = '', item_list_name = '') => {

  return accommodationMoreInfos.map((a, index) => {

    const roomCategory = a.room_id.substring(0, 2);

    const maxPrice = property.prices.filter(p => p.RoomType === a.room_id).map(p => parseFloat(p.PriceStroked));

    const discount = Math.max(...maxPrice) - parseFloat(a.price_from);

    return {
      item_id: `${property.code}-${a.room_id}`,
      item_name: `${property.code}-${a.room_id}`,
      affiliation: 'HuOpenAir',
      coupon: searchForm.Coupon,
      discount: discount,
      index: index,
      item_brand: property.code,
      item_category: property.type, // VI CIT HO
      item_category2: roomCategory,
      item_category3: a.room_id,
      item_category4: `${property.code}_${a.room_id}_${searchForm.GuestArrival}_${searchForm.GuestDeparture}`,
      item_list_id: item_list_id,
      item_list_name: item_list_name,
      location_id: property.location_id,
      price: parseFloat(a.price_from),
      quantity: 1
    };
  });
};

export const gItemsFromCart = (appContext: IAppContext) => {

  return appContext.cart.map((c, index) => {

    const roomCategory = c.RoomType.slice(0, 2);

    const property = appContext.properties.find(p => p.code === appContext.propertySelected);

    if (!property) return;

    const room = property.roomfeed.find(r => r.room_id === c.RoomType);

    if (!room) return;

    const result: any = {
      item_id: `${c.Property}-${c.RoomType}`,
      item_name: `${c.Property}-${c.RoomType}`,
      affiliation: 'HuOpenAir',
      coupon: appContext.searchForms[0].Coupon,
      index: index,
      item_brand: c.Property,
      item_category: property.type, // VI CIT HO
      item_category2: roomCategory,
      item_category3: c.RoomType,
      item_category4: `${c.Property}_${c.RoomType}_${c.RateCode}_${c.GuestArrival}_${c.GuestDeparture}`,
      item_list_id: roomCategory,
      item_list_name: categoriesLabelMapping[roomCategory] ?? '',
      location_id: property.location_id,
      discount: c.discount,
      price: c.TotalStay ?? c.Price,
      quantity: 1
    };

    const maxPrice = property.prices.filter(p => p.RoomType === c.RoomType).map(p => p?.PriceStroked ?? 0);

    let packageSum = 0;

    if (c.PackageCode) {
      c.PackageCode.forEach((pack: IPackageCode) => {
        packageSum += pack.Price || 0;
      });
    }

    const discount = maxPrice.length ? Math.max(...maxPrice) - packageSum - (c.TotalStay ?? c.Price) : 0;

    if (discount) {
      result.discount = Math.abs(discount);
    }

    return result;

  });
};

const gAddUserData = (userId: string) => {

  // const user = await getProfile(appContext.userId);
  return {
    // first_name: user.data.GuestFirstname,
    // last_name: user.data.GuestName,
    phone: localStorage.getItem('t'),
    email: localStorage.getItem('e'),
    customer_id: userId
  };
};

const getCartTotalPrice = (appContext: IAppContext): number => {
  return appContext.cart.reduce((pV, cart) => {
    return pV + (cart.Price ?? cart.TotalStay);
  }, 0);
};

export const getLogo = () => {
  return new URL('../assets/img/' + website.logo.path, import.meta.url).href;
};

export const isCartToBePaidNow = (orders: IOrder[]) => {
  orders[0].RateCode;

  return orders.find(o => o.RateCode.endsWith('_AS') || o.RateCode.endsWith('_AX') || o.RateCode.endsWith('_AS'));
};
